import dynamic from 'next/dynamic'
import api from 'api'

import SettingsHeader from '@lib/table/components/settings-header'
import { ACCESS_LEVELS } from '@lib/config/constants'

const AddSubscriptionModal = dynamic(() =>
  import('@lib/modals/add-license-modal/subscription')
)
const AddEngagementModal = dynamic(() =>
  import('@lib/modals/add-license-modal/engagement')
)
const AddApplicationModal = dynamic(() =>
  import('@lib/modals/add-license-modal/application')
)

export const journeyItems = [
  {
    label: 'Screening',
    key: 'Screening'
  },
  {
    label: 'Onboarding',
    key: 'Onboarding'
  },
  {
    label: 'Formal training',
    key: 'Formal'
  },
  {
    label: 'Surveys',
    key: 'Survey'
  },
  {
    label: 'Peer reviews',
    key: 'Review'
  }
]

export const platformItems = [
  {
    label: 'Individual',
    key: ['Web', 'Mobile']
  },
  {
    label: 'Group training',
    key: ['Group']
  },
  {
    label: 'Class manager',
    key: ['ClassroomManager']
  }
]

export const marketplaceOptions = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
]

export const journeyAccessOptions = [
  {
    label: 'Full access',
    value: ACCESS_LEVELS.fullAccess
  },
  {
    label: 'Engagements only',
    value: ACCESS_LEVELS.viaEngagement
  }
]

export const journeySelectDefault = {
  label: 'No access',
  value: ACCESS_LEVELS.noAccess
}

export const engagementTypes = [
  {
    label: 'Onboarding',
    value: 'Onboarding'
  },
  {
    label: 'Formal training',
    value: 'Formal'
  },
  {
    label: 'Surveys',
    value: 'Survey'
  },
  {
    label: 'Peer reviews',
    value: 'Review'
  }
]

const subscriptionHeaders = [
  { title: 'Subscription group', width: '62%' },
  { title: 'Group status', width: '9rem' },
  'Subscriptions',
  () => <SettingsHeader key='settings' variant='noIcon' />
]
const engagementHeaders = [
  { title: 'Content type', width: '70%' },
  'Engagements available',
  () => <SettingsHeader key='settings' variant='noIcon' />
]
const applicationSeatsHeaders = [
  '',
  () => <SettingsHeader key='settings' variant='noIcon' />
]

export const learnerAccessTypes = [
  {
    label: 'Subscriptions',
    route: api.licences.subscriptions.groups,
    headers: subscriptionHeaders,
    getDeleteModalName: ({ name }) => name,
    hasRenewOption: true,
    emptyText: 'There are no subscription groups created for this account.',
    AddModal: AddSubscriptionModal
  },
  {
    label: 'Engagements',
    route: api.licences.engagements,
    filters: { contentAccessType: engagementTypes.map(({ value }) => value) },
    headers: engagementHeaders,
    getDeleteModalName: ({ contentAccessType }) => contentAccessType,
    emptyText: 'There are no engagements created for this account.',
    AddModal: AddEngagementModal,
    isRight: true
  },
  {
    label: 'Application credits',
    route: api.licences.engagements,
    filters: { contentAccessType: 'Screening' },
    headers: applicationSeatsHeaders,
    getData: ({ contentAccessType }) => contentAccessType === 'Screening',
    getDeleteModalName: ({ contentAccessType }) => contentAccessType,
    AddModal: AddApplicationModal,
    emptyText: 'There are no application credits created for this account.',
    licenseKey: 'Screening'
  }
]

export const modalVariants = {
  add: 'add',
  edit: 'edit',
  rename: 'rename',
  view: 'view',
  renew: 'renew'
}
